import React from 'react';

interface IGeneralService {
    _cloneTitleElement(titleNode: React.ReactNode, text: string): React.ReactNode;
    _cloneAndModifyPlaceHolderOfInputBox(element: React.ReactNode, newText: string): React.ReactNode;
    _formatAmount(amount: string): string;
    _cloneAndModifyUnitOfMeasure(element: React.ReactNode): React.ReactNode;
    getBaseUrl(url: string): string;
    _cloneTitleElementAddToCart(titleNode: React.ReactNode, text: string): React.ReactNode;
}

export class GeneralService implements IGeneralService {
    public _cloneTitleElement(titleNode: React.ReactNode, text: string): React.ReactNode {
        // Base case: if element is not a valid React element, just return it
        if (!React.isValidElement(titleNode)) {
            return titleNode;
        }
        // If the children prop is not a React element (e.g., a string), we modify it directly
        // This assumes the deepest children are text or can be replaced with text
        return React.cloneElement(titleNode as React.ReactElement, { text: text });
    }
    public _cloneAndModifyPlaceHolderOfInputBox(element: React.ReactNode, newText: string): React.ReactNode {
        // Base case: if element is not a valid React element, just return it
        if (!React.isValidElement(element)) {
            return element;
        }

        // Assuming we are modifying the deepest child, which is a simple element or string
        if (element.props && element.props.resources && element.props.resources.searchInputAriaLabel) {
            // If the children prop is a React element, we go deeper
            element.props.resources.searchInputAriaLabel = newText;
            return React.cloneElement(element);
        }
        return element;
    }
    public _formatAmount(amount: string | number): string {
        if (amount !== null || amount !== undefined) {
            // Split the amount by the comma
            if (typeof amount === 'number') {
                amount = amount.toString();
            }
            const parts = amount.split(',');

            // Check if there are two parts and the second part is '00'
            if (parts.length === 2 && parts[1].trim() === '00 kr') {
                // Return the first part with ' kr' at the end, effectively removing ',00'
                return `${parts[0]} kr`;
            }

            // If the condition is not met, return the amount unchanged
            return amount;
        }
        return amount;
    }

    public _cloneAndModifyUnitOfMeasure = (element: React.ReactNode) => {
        // Base case: if element is not a valid React element, just return it
        if (!React.isValidElement(element)) {
            return element;
        }

        // Assuming we are modifying the deepest child, which is a simple element or string
        if (element.props && element.props.children && React.isValidElement(element.props.children)) {
            // If the children prop is a React element, we go deeper
            if (element.props.children.props.children === 'pair') {
                element.props.children.props.children = 'par';
            }
            if (element.props.children.props.children === 'pcs') {
                element.props.children.props.children = ' ';
            }
            const modifiedChildren = React.Children.map(element.props.children, child =>
                this._cloneAndModifyUnitOfMeasure(child)
            ) as React.ReactNode[];
            // Remove the closing parenthesis without any preceding code or statement
            // );
            return React.cloneElement(element, {}, ...modifiedChildren);
        } else {
            // If the children prop is not a React element (e.g., a string), we modify it directly
            // This assumes the deepest children are text or can be replaced with text
            return React.cloneElement(element, {});
        }
    };
    public _cloneAndModifySeeDatailsButton = (element: React.ReactNode) => {
        if (!React.isValidElement(element)) {
            return element;
        }
        if (element.props && element.props.children) {
            const newElement = element;
            // If the children prop is a React element, we go deeper
            newElement.props.children = 'Se detaljer';
            return newElement;
        } else {
            return element;
        }
    };

    public getBaseUrl(url: string): string {
        const parsedUrl = new URL(url);
        const parts = parsedUrl.pathname.split('/');
        // parts[1] should be 'gftest' or its dynamic equivalent
        return `${parsedUrl.protocol}//${parsedUrl.host}/${parts[1]}/`;
    }
    public _cloneTitleElementAddToCart(titleNode: React.ReactNode, text: string): React.ReactNode {
        // Base case: if element is not a valid React element, just return it
        if (!React.isValidElement(titleNode)) {
            return titleNode;
        }
        return React.cloneElement(titleNode as React.ReactElement, { addToCartText: text });
    }
}
